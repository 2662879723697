import { useMenu } from '../hooks/useMenu';

const Topbar = () => {
    const { toggleMenuBar } = useMenu();

    return (
        <>
            <header className="top-bar">
                <button type="button" className="menu-toggler la la-bars" onClick={toggleMenuBar}></button>
                <span className="brand">Perseverance Research Center</span>
            </header>
        </>
    );
}

export default Topbar;
