import { useQuery } from '@tanstack/react-query';
import apiClient from '../utilities/apiClient';


export interface Answser {
  id?: string,
  patientNumber?: string,
  currentQuestion?: string,
  answersInitial?: string,
  answersAilments?: string,
  answersMedications?: string,
  answersListMedications?: string,
  questionnaireDateTime?: string
}

interface GetAnswersResponse {
  records: Answser[],
}

export const useGetAnswers = () => {
  const {
    isLoading,
    isSuccess,
    data,
    isError,
    error,
    refetch,
  } =  useQuery(["answers"], async () => {
      const response = await apiClient.get<GetAnswersResponse>('answers');
      
      return response.data?.records;
  }); 
    
  return {
    isLoading,
    isSuccess,
    data,
    isError,
    error,
    refetch
  }
}