import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { useMenu } from '../hooks/useMenu';
import BasicLayout from './BasicLayout';
import Topbar from './Topbar';
import Menubar from './Menubar';
import Footer from './Footer';

interface AuthLayoutProps {
    menuOption: string,
    children: React.ReactNode,
    sidebar?: React.ReactNode
}

const AuthLayout = (props: AuthLayoutProps) => {
    const { menuOption, children, sidebar } = props;

    const { setMenuBarOption } = useMenu();

    useEffect(() => {
        setMenuBarOption(menuOption);
    }, [menuOption, setMenuBarOption]);

    return (
        <>
            <ToastContainer position="top-right" autoClose={2000} />
            <BasicLayout>
                <Topbar />
                <Menubar />
                <main className="workspace overflow-hidden">
                    {children}
                    <Footer />
                </main>
                {sidebar}
            </BasicLayout>
        </>
    );
}

export default AuthLayout;
