import {useState} from 'react';
import classNames from 'classnames';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel
} from '@tanstack/react-table';

import {PageName} from '../routes';

import AuthLayout from '../components/AuthLayout';
import {Patient, useGetPatients} from '../hooks/usePatients';
import AddPatientModal from '../components/AddPatientModal';
import EditPatientModal from '../components/EditPatientModal';


const columnHelper = createColumnHelper<Patient>();


const tableColumnsSchema = [
    columnHelper.accessor('patientNumber', {
        cell: info => <div className="font-bold">{info.getValue()}</div>,
        header: () => <div className="text-left uppercase">Patient Number</div>,
    }),
    columnHelper.accessor('phoneNumber', {
        cell: info => <div className="">{info.getValue()}</div>,
        header: () => <div className="text-left uppercase">Phone Number</div>,
    }),
    columnHelper.accessor('lastResponseDate', {
        cell: info => {
            const dateValue = info.getValue();

            if (dateValue === undefined || dateValue === null) {
                return <div className=""></div>
            }

            const justDate = new Date(dateValue).toLocaleDateString("en-us")

            return <div className="">{justDate}</div>;
        },

        header: () => <div className="text-left uppercase">Last Response Date</div>,
    }),
    columnHelper.accessor('deactivated', {
        cell: info => {
            const deactivated = info.getValue();

            const badgeClasses = {
                'badge badge_outlined uppercase': true,
                'badge_danger': deactivated,
                'badge_success': !deactivated,
            };

            const text = deactivated ? 'Deactivated' : 'Active';

            return (
                <div className={classNames(badgeClasses)}>{text}</div>
            );
        },
        header: () => <div className="text-left uppercase">Status</div>,
    }),
];

const Patients = () => {
    const [updatePatient, setUpdatePatient] = useState<Patient>();
    const [sorting, setSorting] = useState<SortingState>([])

    const {
        isLoading,
        isSuccess,
        data: patients,
    } = useGetPatients();

    const table = useReactTable({
        data: patients || [],
        columns: tableColumnsSchema,
        state: {
            sorting
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <>
            <AuthLayout menuOption={PageName.Patients}>
                <section className="breadcrumb lg:flex items-start">
                    <div>
                        <h1>Patients</h1>
                    </div>
                    <div className="flex flex-wrap gap-2 items-center ml-auto mt-5 lg:mt-0">
                        <AddPatientModal/>
                        <a className="btn btn_primary uppercase"
                           href={process.env.REACT_APP_API_BASE_URL + "patients/export"}>Export Data</a>
                    </div>
                </section>
                <div className="card p-5">
                    <div className="overflow-x-auto">
                        <EditPatientModal patient={updatePatient} clearPatient={() => setUpdatePatient(undefined)}/>
                        <table className="table table-auto table_hoverable w-full">
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id}>
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? 'cursor-pointer select-none'
                                                            : '',
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                </div>
                                            )}

                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody>
                            {isLoading && <tr>
                                <th colSpan={tableColumnsSchema.length}>Loading...</th>
                            </tr>}
                            {isSuccess && table.getRowModel().rows.map((row) => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                    <td>
                                        <button type="button"
                                                className="btn btn-icon btn-icon_large btn_outlined btn_secondary"
                                                onClick={() => setUpdatePatient(row.original)}>
                                            <span className="la la-edit"></span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
}

export default Patients;
