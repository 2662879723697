import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Patient, useUpdatePatient} from "../hooks/usePatients";

import PatientModal from "./PatientModal";


interface EditPatientModalProps {
    patient?: Patient,
    clearPatient: () => void,
}

const EditPatientModal = (props: EditPatientModalProps) => {
    const {patient, clearPatient} = props;

    const [showModal, setShowModal] = useState(false);

    const {
        isLoading: updatingPatient,
        update: updatePatient,
    } = useUpdatePatient();

    const toggleModalVisibility = () => {
        clearPatient();
        setShowModal(!showModal);
    };

    useEffect(() => {
        if (patient) {
            setShowModal(true);
        }
    }, [patient]);
    
    const onSubmit = (data: Patient) => {
        updatePatient(data).then(() => {
            toggleModalVisibility();
            toast.success('Patient updated successfully');
        }).catch((errors) => {
            const hasGeneralError = !!errors?.generalErrors?.length;

            if (Object.keys(errors || {}).length <= 1) {
                const message = hasGeneralError ?
                    'An error occured: ' + errors?.generalErrors[0] :
                    'An unknown error occurred, if the issue persists contact your system administrator';

                toast.error(message);
            }
        });
    };

    return (
        <>
            <PatientModal
                showModal={showModal}
                toggler={toggleModalVisibility}
                isProcessing={updatingPatient}
                onSubmit={onSubmit}
                data={patient}
            />
        </>
    );
};

export default EditPatientModal;
