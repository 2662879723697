import { useState } from "react";
import { toast } from "react-toastify";

import {Patient, useCreatePatient} from "../hooks/usePatients";
import PatientModal from "./PatientModal";

const AddPatientModal = () => {
    const [showModal, setShowModal] = useState(false);

    const {
        isLoading: creatingPatient,
        create: createPatient,
    } = useCreatePatient();

    const toggler = () => {
        setShowModal(!showModal);
    };

    const onSubmit = (data: Patient) => {
        createPatient(data).then(() => {
            toggler();
            toast.success('Patient created successfully');
        }).catch((errors) => {
            const hasGeneralError = !!errors?.generalErrors?.length;

            if (Object.keys(errors).length <= 1) {
                const message = hasGeneralError ?
                    'An error occured: ' + errors?.generalErrors[0] :
                    'An unknown error occurred, if the issue persists contact your system administrator';

                toast.error(message);
            }
        });
    };

    return (
        <>
            <button className="btn btn_primary uppercase" onClick={toggler}>Add Patient</button>
            <PatientModal
                showModal={showModal}
                toggler={toggler}
                isProcessing={creatingPatient}
                onSubmit={onSubmit}
            />
        </>
    );
};

export default AddPatientModal;
