import {createColumnHelper, flexRender, getCoreRowModel, useReactTable,} from '@tanstack/react-table';

import {PageName} from '../routes';
import {Answser, useGetAnswers} from '../hooks/useQuestions';
import AuthLayout from '../components/AuthLayout';

const columnHelper = createColumnHelper<Answser>();

const tableColumnsSchema = [
    columnHelper.accessor('questionnaireDateTime', {
        cell: info => {
            const dateValue = info.getValue();

            if(dateValue === undefined){
                return <div className=""></div>;    
            }
            
            const justDate = new Date(dateValue).toLocaleDateString("en-us")

            return <div className="">{justDate}</div>;
            
        },
        header: () => <div className="text-left uppercase">Date</div>

    }),
    columnHelper.accessor('patientNumber', {
        cell: info => <div className="font-bold">{info.getValue()}</div>,
        header: () => <div className="text-left uppercase">Patient</div>
    }),
    columnHelper.accessor('currentQuestion', {
        cell: info => <div className="">{info.getValue()}</div>,
        header: () => <div className="text-left uppercase">Status</div>
    }),
    columnHelper.accessor('answersInitial', {
        cell: info => <div className="">{info.getValue()}</div>,
        header: () => <div className="text-left uppercase">Have you experienced any changed in your health?</div>
    }),
    columnHelper.accessor('answersAilments', {
        cell: info => <div className="">{info.getValue()}</div>,
        header: () => <div className="text-left uppercase">Please describe any changes you've experienced.</div>
    }),
    columnHelper.accessor('answersMedications', {
        cell: info => <div className="">{info.getValue()}</div>,
        header: () => <div className="text-left uppercase">Have there been any changes to your medications?</div>
    }),
    columnHelper.accessor('answersListMedications', {
        cell: info => <div className="">{info.getValue()}</div>,
        header: () => <div className="text-left uppercase">Medication List</div>
    }),
];

const Answers = () => {
    const {
        isLoading,
        isSuccess,
        data: answers,
    } = useGetAnswers();

    const table = useReactTable({
        data: answers || [],
        columns: tableColumnsSchema,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <>
            <AuthLayout menuOption={PageName.Answers}>
                <section className="breadcrumb lg:flex items-start">
                    <div>
                        <h1>Answers</h1>
                    </div>
                </section>
                <div className="card p-5">
                    <div className="overflow-x-auto">
                        <table className="table table-auto table_hoverable w-full">
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody>
                            {isLoading && <tr>
                                <th colSpan={tableColumnsSchema.length}>Loading...</th>
                            </tr>}
                            {isSuccess && table.getRowModel().rows.map((row) => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
}

export default Answers;