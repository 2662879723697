import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

interface BaseFormControlProps {
    label?: string,
    register?: {
        onChange: (event: any) => void,
        value?: any,
        ref?: (instance: any) => void,
        name?: string,
    },
    clientErrors?: {
        message?: string,
    },
    serverErrors?: string[],
    disabled?: boolean,
}

interface FormControlProps extends BaseFormControlProps {
    children: React.ReactNode,
}

interface FormInputControlProps extends BaseFormControlProps {
    type?: 'text' | 'number' | 'date' | 'time' | 'datetime-local',
}

interface FormTextAreaControlProps extends BaseFormControlProps {
}

interface FormCheckboxControlProps extends BaseFormControlProps {
    onChange?: (value?: boolean) => void,
}

interface FormSelectControlProps extends BaseFormControlProps {
    options?: {
        value: string,
        text: string
    }[] | string[],
    onChange?: (value?: string) => void,
    hideEmpty?: boolean,
}

interface FromNumberControlProps extends BaseFormControlProps { }

const FormControl = (props: FormControlProps) => {
    const { label, register, clientErrors, serverErrors, disabled, children } = props;

    const child = React.Children.only(children as React.ReactElement<any>);

    const childParams = {
        ...register,
        disabled,
        className: child.props?.className || classNames({
            'form-control': true,
            'is-invalid': (!!clientErrors || !!serverErrors),
            'disabled:opacity-50': disabled,
        })
    };

    return (
        <>
            {!!label && <label className="label block mb-2">{label}</label>}
            {React.cloneElement(child, { ...childParams })}
            {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) =>
                <small key={index} className="block mt-1 invalid-feedback">{error}</small>
            )}
        </>
    );
};

export const FormInputControl = (props: FormInputControlProps) => {
    const { type, register } = props;

    return (
        <>
            <FormControl {...props}>
                <input type={type} {...register} />
            </FormControl>
        </>
    );
};

export const FormTextAreaControl = (props: FormTextAreaControlProps) => {
    return (
        <>
            <FormControl {...props}>
                <textarea />
            </FormControl>
        </>
    );
};

export const FormCheckboxControl = (props: FormCheckboxControlProps) => {
    const { label, onChange, register, clientErrors, serverErrors, disabled } = props;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event?.target.checked);
        }
    }

    const checkboxClasses = {
        'form-control': true,
        'is-invalid': (!!clientErrors || !!serverErrors),
    };

    return (
        <>
            <label className="custom-checkbox">
                <input
                    type="checkbox"
                    className={classNames(checkboxClasses)}
                    disabled={disabled}
                    onChange={handleOnChange}
                    {...register}
                />
                <span></span>
                <span>{label}</span>
                {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
                {serverErrors?.map((error, index) =>
                    <small key={index} className="block mt-1 invalid-feedback">{error}</small>
                )}
            </label>
        </>
    );
};

export const FormNumberControl = (props: FromNumberControlProps) => {
    const { register, disabled, ...rest } = props;

    const { ref, ...registerBind } = register || {};

    const childParams = {
        ...registerBind,
        className: classNames({
            'form-control': true,
            'is-invalid': (!!props.clientErrors || !!props.serverErrors),
            'disabled:opacity-50': disabled,
        })
    };

    return (
        <>
            <FormControl {...rest}>
                <div className="form-number input-group">
                    <NumberFormat
                        getInputRef={ref}
                        type="tel"
                        isNumericString={true}
                        format="##########"
                        {...childParams}
                    />
                </div>
            </FormControl>
        </>
    );
};

export const FormSelectControl = (props: FormSelectControlProps) => {
    const { options, onChange, hideEmpty, register, disabled, ...rest } = props;

    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (onChange) {
            onChange(event?.target.value);
        }
    }

    const childParams = {
        ...register,
        className: classNames({
            'form-control': true,
            'is-invalid': (!!props.clientErrors || !!props.serverErrors),
            'disabled:opacity-50': disabled,
        })
    };

    return (
        <>
            <FormControl {...rest}>
                <div className="custom-select">
                    <select disabled={disabled} onChange={handleOnChange} {...childParams}>
                        {!hideEmpty && <option value=""></option>}
                        {options?.map((option, index) => {
                            const value = typeof (option) === 'string' ? option : option.value;
                            const text = typeof (option) === 'string' ? option : option.text;

                            return (
                                <option key={index} value={value}>{text}</option>
                            );
                        })}
                    </select>
                    <div className="custom-select-icon la la-caret-down" />
                </div>
            </FormControl>
        </>
    );
};
