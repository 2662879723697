import React from 'react';
import classNames from 'classnames';

interface ModalBaseProps {
    toggler: () => void,
    children: React.ReactNode,
    disableClose?: boolean
}

interface ModalProps extends ModalBaseProps {
    isOpen: boolean,
    size?: string,
    isStatic?: boolean,
    isScrollable?: boolean,
    isCentered?: boolean,
    isFullHeight?: boolean,
}

export const ModalHeader = (props: ModalBaseProps) => {
    const { children, toggler, disableClose } = props;

    return (
        <>
            <div className="modal-header">
                <h2 className="modal-title">
                    {children}
                </h2>
                <button type="button" className="close la la-times" onClick={toggler} disabled={disableClose}></button>
            </div>
        </>
    );
}

export const ModalBody = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <div className="modal-body">
                {children}
            </div>
        </>
    );
}

export const ModalFooter = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <div className="modal-footer">
                {children}
            </div>
        </>
    );
}

export const Modal = (props: ModalProps) => {
    const {
        isOpen,
        toggler,
        disableClose,
        size,
        isStatic,
        isScrollable,
        isCentered,
        isFullHeight,
        children
    } = props;

    if (!isOpen) {
        return null;
    }

    const handleOnClick = () => {
        if (isStatic || disableClose) {
            return;
        }

        toggler();
    }

    const modalClasses = {
        'modal-dialog': true,
        'modal-dialog_scrollable': isScrollable,
        'modal-dialog_centered': isCentered,
        'h-screen': isFullHeight,
    };

    const modalSize =
        size === 'sm' ? 'max-w-sm' :
            size === 'md' ? 'max-w-2xl' :
                size === 'lg' ? 'max-w-5xl' :
                    size === 'full' ? 'max-w-full' :
                        'max-w-2xl';

    return (
        <>
            <div className="modal active" onClick={handleOnClick}>
                <div className={classNames(modalClasses, modalSize)} onClick={(e) => e.stopPropagation()}>
                    <div className="modal-content w-full">
                        {children}
                    </div>
                </div>
            </div>
            <div className={`${isOpen ? 'opacity-25' : 'opacity-0'} fixed inset-0 z-40 bg-black transition-all duration-500`} />
        </>
    );
}
