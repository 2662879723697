import { Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
    useLocation();
    return (
        <Outlet />
    );
}

export default PrivateRoute;
