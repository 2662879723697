import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './scss/app.scss';

import { AppRoutes } from './routes';
import apiClient from './utilities/apiClient';
import { MenuProvider } from './hooks/useMenu';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './pages/NotFound';

const App = () => {
    apiClient.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                retry: false
            }
        }
    });

    const privateRoutes = AppRoutes.filter((route) => route.isPrivate);
    const publicRoutes = AppRoutes.filter((route) => !route.isPrivate);

    return (
        <>
            <BrowserRouter>
                    <QueryClientProvider client={queryClient}>
                        <MenuProvider>
                            <Routes>
                                <Route path="/" element={<PrivateRoute />}>
                                    {privateRoutes.map((route, index) =>
                                        <Route key={index} path={route.path} element={route.element} />
                                    )}
                                </Route>
                                {publicRoutes.map((route, index) =>
                                    <Route key={index} path={route.path} element={route.element} />
                                )}
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </MenuProvider>
                    </QueryClientProvider>
            </BrowserRouter>
        </>
    );
};

export default App;
