import React from 'react';

const BasicLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <div className="main-body">
                {children}
            </div>
        </>
    );
}

export default BasicLayout;
