import AuthLayout from '../components/AuthLayout';

const NotFound = () => {
    return (
        <>
            <AuthLayout menuOption="Not Found">
                <div className="container flex items-center justify-center mt-20 py-10">
                    <div className="w-full md:w-1/2 xl:w-1/3">
                        <div className="mx-5 md:mx-10 text-center uppercase">
                            <h1 className="text-9xl font-bold">404</h1>
                            <h2 className="text-primary mt-5">Not Found</h2>
                            <h5 className="mt-2">What you're looking for is not here.</h5>
                        </div>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
}

export default NotFound;
