import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

import {FormCheckboxControl, FormInputControl, FormNumberControl} from './FormControls';
import {Modal, ModalBody, ModalFooter, ModalHeader} from './Modal';
import {Patient} from "../hooks/usePatients";
import {useEffect} from "react";


interface PatientFormProps {
    showModal: boolean,
    toggler: () => void,
    isProcessing: boolean,
    onSubmit: (data: Patient) => void,
    data?: Patient,
}

const validationSchema: yup.SchemaOf<Patient> = yup.object({
    patientNumber: yup.string().required('Patient Number is required.'),
    phoneNumber: yup.string().required('Phone Number is required.'),
    id: yup.string().optional(),
    deactivated: yup.boolean().optional(),
    lastResponseDate: yup.string().nullable().optional()
}).required();

const formDefaultValues = {
    id: '',
    patientNumber: '',
    phoneNumber: '',
    deactivated: false,
    lastResponseDate: '',
};

const PatientModal = (props: PatientFormProps) => {
    const {showModal, toggler, isProcessing, onSubmit, data} = props;

    const resetForm = () => {
        resetClient();
        toggler();
    };

    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: {
            errors: clientErrors
        },
    } = useForm<Patient>({
        resolver: yupResolver(validationSchema),
        defaultValues: formDefaultValues
    });

    useEffect(() => {
        showModal && data ?
            resetClient(data, {keepDefaultValues: true}) :
            resetClient(formDefaultValues, {keepDefaultValues: false});
    }, [showModal, data, resetClient]);
    return (
        <>
            <Modal isOpen={showModal} toggler={resetForm} size="sm" isStatic={true} disableClose={isProcessing}>
                <ModalHeader toggler={resetForm}>Patient Info</ModalHeader>
                <ModalBody>
                    <div className="grid grid-cols-1 gap-5">
                        <div>
                            <FormInputControl
                                type="text"
                                label="Patient Number"
                                register={register('patientNumber')}
                                clientErrors={clientErrors?.patientNumber}
                                disabled={isProcessing}
                            />
                        </div>
                        <div>
                            <FormNumberControl
                                label="Phone Number"
                                register={register('phoneNumber')}
                                clientErrors={clientErrors?.phoneNumber}
                                disabled={isProcessing}
                            />
                        </div>
                        <div>
                            <FormCheckboxControl
                                label="Deactivated"
                                register={register('deactivated')}
                                clientErrors={clientErrors?.deactivated}
                                disabled={isProcessing}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button type="button" className="btn btn_secondary uppercase" disabled={isProcessing}
                                onClick={toggler}>Close
                        </button>
                        <button type="submit" className="btn btn_primary ml-2 uppercase" disabled={isProcessing}
                                onClick={handleSubmit(onSubmit)}>Save
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default PatientModal;
